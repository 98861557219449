import { RichTextSchema } from "@storyblok/vue";

const CustomRichTextSchema = computed(() => {
  RichTextSchema.marks.link = (node) => {
    if (!node.attrs) {
      return {
        tag: "",
      };
    }

    const isEmailLinkType = (type: string) => type === "email";
    const isUrlLinkType = (type: string) => ['url','story'].includes(type);

    const escapeHTML = function (str: string) {
      const htmlEscapes: { [key: string]: string } = {
        "&": "&amp;",
        "<": "&lt;",
        ">": "&gt;",
        '"': "&quot;",
        "'": "&#39;",
      };

      const reUnescapedHtml = /[&<>"']/g;
      const reHasUnescapedHtml = RegExp(reUnescapedHtml.source);

      return str && reHasUnescapedHtml.test(str) ? str.replace(reUnescapedHtml, (chr) => htmlEscapes[chr]) : str;
    };
    const attrs = { ...node.attrs };
    const { linktype = "url" } = node.attrs;
    delete attrs.linktype;

    if (isEmailLinkType(linktype)) {
      attrs.href = `mailto:${attrs.href}`;
    } else if (isUrlLinkType(linktype)) {
      if (attrs.href) {
        attrs.href = escapeHTML(node.attrs.href || "");

        if (!attrs.href.startsWith("http://") && !attrs.href.startsWith("https://") && !attrs.href.startsWith("mailto:") && !attrs.href.startsWith("tel:")) {
          attrs.href = "/" + attrs.href.replace(/^\/+/, "");
        }
      }
    }
    
    if (attrs.anchor) {
      attrs.href = `${attrs.href}#${attrs.anchor}`;
      delete attrs.anchor;
    }

    if (attrs.custom) {
      for (const key in attrs.custom) {
        attrs[key] = attrs.custom[key];
      }
      delete attrs.custom;
    }

    return {
      tag: [
        {
          tag: "a",
          attrs: attrs,
        },
      ],
    };
  };

  RichTextSchema.nodes.emoji = (node) => {

    if(!node.attrs.emoji){

      const attrs = {
        ["data-type"]: "emoji",
        ["data-name"]: node.attrs.name,
        class: 'emoji-fallback',
        emoji: node.attrs.emoji || node.attrs.name,
      };

      node.attrs.emoji = node.attrs.name;
      node.type = "paragraph";
      node.content = [{ text: node.attrs.name, type: "text", marks: [{ type: "italic", attrs: attrs }] }];

    }
   
  };

  RichTextSchema.marks.italic = (node) => {
    if(node?.attrs?.class === 'emoji-fallback'){

      return {
        tag: [
          {
            tag: 'i',
            attrs: node.attrs,
          },
        ],
      }
    }

    return {
      tag: 'i',
    }
  
  }

  return RichTextSchema;
});

export const useRenderRichText = (content: any) => {
  if (Object.values(content).length === 0) return "";
  return renderRichText(content, {
    schema: CustomRichTextSchema.value,
    resolver: (component, blok) => {
      const blokString = JSON.stringify(blok).replace(/&/g, "&amp;").replace(/</g, "&lt;").replace(/>/g, "&gt;").replace(/"/g, "&quot;").replace(/'/g, "&#39;");
      return `<component :blok='${blokString}' is="content-${component}" />`;
    },
  });
};
